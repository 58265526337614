@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --white: #fff;
  --black: #000;
  --blue: #0087CA;
  --yellow: #F5C714;
  --red: #BC5828;
  --gray: #708088;


  --white2: #EDEDED;
  --reserv: #00ca437e;
}

html{
  scroll-behavior: smooth;
}

.container{
  margin: auto;
  max-width: 1168px;
 }
.map-cont iframe{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.bg-img{
  background-image: url("./images/historyhead.png");
  border-radius: 8px;
  background-repeat: no-repeat;
}

.s-bg-img{
  background-image:url("./images/s-bg-1.png");
}
a,
button{
  outline: none;
}


#onclick{
  display: none;
}

.lbl-resp{
  position: relative;
  float: right;
  color: white;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 1208px) {
  .container{
    padding-left: 16px;
    padding-right: 16px;
  }
}



@media screen and (max-width: 1000px) { 
  .lbl-resp{
    display: block;
  }

  .rsp-header{
    margin-top: 100px;
  }
 

  .rsp-nav{
    position: fixed;
    top: 0px;
  }
  .menu{
    position: fixed;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  .menu label{
    right: 30px;
  }
  .menu ul{
    position: absolute;
    right: 0px;
    margin-top: 60px ;
    margin-left: 0px;
    width: 50%;
    flex-direction: column;
    background-color: var(--blue);
    display: none;
    height: 100vh;
    display: none;

  }

  .menu ul li{
    display: block;
    width: 100%;
    padding: 20px;  
    
  }

  .menu ul li a{
    width: 100%;
  }

  #onclick:checked~ul{
    display: block;
  }
}

@media screen and (max-width: 960px) {
  .rsp-footer{
    flex-direction: column;
    width: 100%;
    gap: 52px;
    justify-content: space-between;
  }

  .footer-rsp-1{
    width: 100%;
  }

  .footer-rsp-2{
    gap:32px;
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
   .footer-rsp-1{
    flex-direction: column;
    width: 100%;
   } 
   .footer-rsp-2{
    flex-direction: column;
    width: 100%;
    align-items: center;
   } 
}

.navlinks li{
  position: relative;
}
.active::after,
.navlinks li::after{
  content: "";
  position: absolute;
  background-color: white;
  height: 4px;
  width: 0%;
  left: 0;
  bottom: -38px;
  transition: 0.2s;
}
.active::after,
.navlinks li:hover::after{
  width: 100%;
}
@media screen and (max-width:1000px) {
  .navlinks li:hover::after{
    width: 0%;
  }
  .active::after,
.navlinks li::after{
  content: "";
  position: absolute;
  background-color: white;
  height: 4px;
  width: 0%;
  left: 0;
  bottom: -38px;
  transition: 0.2s;
}
.active::after,
.navlinks li:hover::after{
  width: 0%;
}
}


.hover-btn img{
  filter: invert(100%) brightness(200%);
}

.hover-btn:hover img{
  filter: invert(100%) brightness(0%);
}
.brownImg img{
  filter: invert(38%) sepia(87%) saturate(399%) hue-rotate(335deg) brightness(94%) contrast(107%);
}
.react-multi-carousel-list{
  padding-bottom: 35px !important;
}
.custom-dot-list-style{
  margin-bottom: 10px !important;
  gap: 10px;
}
.react-multi-carousel-dot button,
.react-multi-carousel-dot{
  width: 16px !important;
  height: 16px !important;
}
.carousel-container2 ul{
  gap:30px !important;
}
.react-multi-carousel-dot--active button{
  background-color: #747474 !important;
}

.carousel-container2 .react-multiple-carousel__arrow--left,
.carousel-container2 .react-multiple-carousel__arrow--right{
  display: none !important;
}

.list li{
  list-style: disc !important;
  margin-left: 25px !important;
}

.carousel-container .react-multi-carousel-item--active h1{
  animation: inOut 0.6s linear;
}
@keyframes inOut{
  from {margin-top: 100px;}
  to {margin-top: 0px;}
}

.react-multiple-carousel__arrow{
  background-color:  none !important;
  background:  none !important;
}
.react-multiple-carousel__arrow::before{
  font-size: 50px !important;
}

.grid-full-168{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(168px, 1fr));
}